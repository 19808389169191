import React, {useEffect, useState} from 'react';
import './_dynamic-hotspot.scss';
import useStore from "../../store";
import {IImage} from "../../interfaces";


interface DynamicHotspotProps {
    img: IImage
}

export default function DynamicHotspot({img}: DynamicHotspotProps) {
    const {SetLoadedImages} = useStore();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!loading) {
            SetLoadedImages(img.hotspot!)
        }
    }, [loading])


    return (
        <div className="sub-image"
             style={img.style}
        >
            <div>
                <img src={`${img.src}?${Date.now()}`}
                     alt={"image_" + img.id}
                     style={img.imageStyle}
                     onLoad={() => {
                         setTimeout(() => {
                             if (loading) {
                                 setLoading(false);
                             }
                         }, 1000)
                     }}
                />
            </div>
        </div>
    );
}
