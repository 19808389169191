import React, {useState} from 'react';
import './_controller.scss';
import useStore from "../../store";
import {Instruction} from "../index";

export default function Controller() {
    const {CurScene, SetCurScene} = useStore();

    const [showInstruction, SetShowInstruction] = useState<boolean>(false)

    function setCurScene() {
        if (CurScene === 'scene-1') {
            SetCurScene("scene-2")
        } else if (CurScene === 'scene-2') {
            SetCurScene("scene-3")
        } else {
            SetCurScene('scene-1')
        }
    }

    return (<div className="controller">
        <div className="btn-list">
            <button className="btn" onClick={setCurScene}>Switch scene</button>
            <button className="btn how-to-use" onClick={() => SetShowInstruction(!showInstruction)}>
                <img src="./assets/images/info.svg" alt="How to use"/>
            </button>
        </div>
        <Instruction show={showInstruction} closeInstruction={() => SetShowInstruction(false)}/>
    </div>);
}

