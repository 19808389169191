import React, {useEffect, useState} from 'react';
import './_hotspots.scss';
import useStore from "../../store";
import Utils from "../../utils";
import {Hotspot, Popup} from "../index";

export default function Hotspots() {
    const {CurScene, LoadedImages} = useStore();

    const [data, SetData] = useState<number[] | null>([])

    let hsId: number | null = null;

    const _hsId = Utils.useQuery().get('hs');
    if (_hsId) {
        hsId = parseInt(_hsId);
    }

    const [curHotspot, SetCurHotspot] = useState<number | null>(hsId)

    useEffect(() => {
        if (CurScene) {
            const _data = Utils.getScene(CurScene).hotspotList;
            if (_data && _data.length) {
                SetData(_data);
            } else {
                SetData(null)
            }

        } else {
            SetData(null)
        }
    }, [CurScene])

    return (
        <div className='hotspot-container'>
            <img src="./assets/images/blank.svg" alt=""/>
        {
            data?.length ? data.map((id) => (
                    <Hotspot id={id}
                             setCurHotspot={() => SetCurHotspot(id)}
                             key={'hs' + id}
                    />
                )
            ) : ''
        }
        {
            LoadedImages.map((id) => (
                <Hotspot id={id}
                         setCurHotspot={() => SetCurHotspot(id)}
                         key={'hs_' + id}
                />
            ))
        }
        <Popup curHotspot={curHotspot} closePopup={() => SetCurHotspot(null)}/>
    </div>
    );
}
