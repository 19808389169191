import React, {useEffect, useState} from 'react';
import {IHotspot} from "../../interfaces";
import Utils from "../../utils";

export type HotspotProps = {
    id: number,
    setCurHotspot: () => void
}

export default function Hotspot({id, setCurHotspot}: HotspotProps) {

    const [data, SetData] = useState<IHotspot | null>(null);

    useEffect(() => {
        if (id) {
            SetData(Utils.getHotspotInfo(id) || null)
        }
    }, [id])

    return (
        data ?
            <div
                id={id.toString()}
                className='hs'
                 style={{...data.style}}
                 onClick={setCurHotspot}
            >
                <div className='hs-icon'></div>
                <div className={'hs-content ' + data.side}>
                    {
                        data.pigment ?
                            <span>{data.pigment}</span> : ''
                    }
                    <span>{data.name}</span>
                </div>
            </div> : <></>
        );
}
