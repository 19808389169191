import React, {useEffect, useState} from 'react';
import './_home.scss';
import Utils from "../../utils";
import {IScene} from "../../interfaces";
import {Swipe, Hotspots, Controller, SubImage} from "../index";
import useStore from "../../store";

export default function Home() {

    const {CurScene} = useStore();

    const [data, SetData] = useState<IScene>();

    useEffect(() => {
        if (CurScene) {
            SetData(Utils.getScene(CurScene))
        }

    }, [CurScene])

    const ImageContainer = <>
        <div className="image-container">
            <Hotspots/>
            <SubImage/>
        </div>
        <Controller/>
        <Swipe/>
    </>

    return (<div className={"adv-home " + (data?.theme) + (Utils.getScreenClass())}>
        {
            data ? ImageContainer: ''
        }
    </div>);
}
