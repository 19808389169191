import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './index.css';
import {Layout, Home} from "./components";
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('ad-root') as HTMLElement
);

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path='/:id' index={true} element={<Home/>}/>
                </Route>
                <Route path='*' element={<Home/>}/>
            </Routes>
        </BrowserRouter>
    )
}

root.render(
    <App />
);

