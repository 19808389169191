import React, {useEffect, useState} from 'react';
import Spinner from 'react-bootstrap/Spinner';


interface InstructionProps {
    show: boolean,
    closeInstruction: () => void
}
export default function Instruction({show, closeInstruction}: InstructionProps) {

    const [loadedIframe, SetLoadedIframe] = useState<boolean>(false);

    const [expanded, SetExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                SetExpanded(true);
            }, 1100)
        } else {
            SetExpanded(false)
        }
    }, [show])

    return (<div className={"popup" + (show ? ' show' : '')}>
        <div className="popup-backdrop"
             onClick={closeInstruction}/>
        <div className="d-flex flex-column position-relative w-100 h-100" style={{zIndex: 1000}}>
            <div className="header">
                <div className="close-btn" onClick={closeInstruction}>
                    &#215;
                </div>
            </div>
            <iframe
                className="h-100 w-100"
                src="https://www.kronosww.com/how-to-use/"
                title='How to use'
                frameBorder={0}
                onLoad={() => (SetLoadedIframe(true))}
            />
        </div>
        <div className={'loading-container' + (loadedIframe && expanded ? ' d-none' : '')} >
            <Spinner animation="border" variant="primary"/>
        </div>
    </div>);
}
