import React from 'react';
import './_swipe.scss';

export default function Swipe() {
    return (
        <div className="swipe" onClick={(e) => {
            e.currentTarget.classList.add('d-none')
        }}>
            <img src="./assets/images/swipe.svg" alt="Swipe"/>
            <h6 className="mt-3"><b>SWIPE TO EXPLORE MORE</b></h6>
        </div>
    );
}
