import create from "zustand";
import {devtools} from "zustand/middleware";
import {ISceneName} from "../interfaces";

interface IStore {
    CurScene: ISceneName,
    LoadedImages: number[],

    SetCurScene: (scene: ISceneName) => void,
    SetLoadedImages: (hsIdx: number) => void,
}

const useStore = create<IStore>()(
    devtools(
        (set, get) => ({
            CurScene: 'scene-1',
            LoadedImages: [],

            SetCurScene: (scene) => {
                if (scene !== get().CurScene) {
                    set(() => ({
                        CurScene: scene,
                        LoadedImages: []
                    }))
                }

            },
            SetLoadedImages: (hsIdx) => {
                let _loadedImage = [...get().LoadedImages];
                if (!_loadedImage.includes(hsIdx)) {
                    _loadedImage.push(hsIdx);
                    set(() => ({
                        LoadedImages: _loadedImage
                    }))
                }
            }
        })
    )
)

export default useStore;

