import React, {useEffect, useState} from 'react';
import './_sub-image.scss';
import useStore from "../../store";
import Utils from "../../utils";
import {IImage} from "../../interfaces";
import {DynamicHotspot} from "../index";

export default function SubImage() {
    const {CurScene} = useStore();

    const [data, SetData] = useState<IImage[] | null>([]);

    const [dynamicHotspot, SetDynamicHotspot] = useState<IImage[] | null>([])


    useEffect(() => {
        if (CurScene) {
            const {images, dynamicHotspot} = Utils.getScene(CurScene)
            SetData(images);
            SetDynamicHotspot(dynamicHotspot)
        } else {
            SetData(null);
            SetDynamicHotspot(null)
        }
    }, [CurScene])


    return (<>
        {
            data?.length ? <>
                {
                    data.map((img, idx) => (
                        <div className="sub-image"
                             style={img.style}
                             key={img.id}
                        >
                            <div>
                                <img src={img.src} alt={"image_" + idx} style={img.imageStyle}/>
                            </div>
                        </div>
                    ))
                }
            </> : ''
        }
        {
            dynamicHotspot?.length ? <>
                {
                    dynamicHotspot.map((img) => (
                        <DynamicHotspot img={img} key={'dynamic_hotspot_' + img.id}/>
                    ))
                }
            </> : ''
        }
    </>);
}
