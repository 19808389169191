import React, {useEffect, useState} from 'react';
import './_popup.scss';
import Utils from "../../utils";
import {IHotspot} from "../../interfaces";
import useStore from "../../store";
import Spinner from "react-bootstrap/Spinner";


interface PopupProps {
    curHotspot: number | null,
    closePopup: () => void
}
export default function Popup({curHotspot, closePopup}: PopupProps) {
    const {CurScene, SetCurScene} = useStore();

    const [data, SetData] = useState<IHotspot | null>();

    const [loadedIframe, SetLoadedIframe] = useState<boolean>(false);

    const [expanded, SetExpanded] = useState<boolean>(false);



    useEffect(() => {
        if (curHotspot) {
            const _data = Utils.getHotspotInfo(curHotspot);
            if (_data) {
                SetData(_data);
                if (CurScene !== _data.scene) {
                    SetCurScene(_data.scene!)
                }
            } else {
                SetData(null)
            }
        } else {
            SetData(null)
        }
    }, [curHotspot])

    useEffect(() => {
        if (data) {
            setTimeout(() => {
                SetExpanded(true);
            }, 1100)
        } else {
            SetExpanded(false)
        }
    }, [data])

    return (<div className={"popup" + (data ? ' show' : '')}>
        <div className="popup-backdrop"
             onClick={closePopup}
        ></div>
        <div className="d-flex flex-column position-relative w-100 h-100" style={{zIndex: 1000}}>
            <div className="header">
                <div className="close-btn" onClick={closePopup}>
                    &#215;
                </div>
            </div>
            <div className="flex-grow-1">
                <iframe className="h-100 w-100"
                        src={data?.link}
                        title='Hotspot'
                        onLoad={() => (SetLoadedIframe(true))}
                />
            </div>
        </div>
        <div className={'loading-container' + (loadedIframe && expanded ? ' d-none' : '')} >
            <Spinner animation="border" variant="primary"/>
        </div>
    </div>);
}
