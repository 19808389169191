import {IScene, ISceneName} from "../interfaces";
import {HotspotData} from "./data";

const SCENE: { [name in string]: IScene } = {
    'scene-1': {
        'theme': 'dark',
        hotspotList: [11, 17, 18, 19, 20, 21, 22, 24],
        'bg': `./assets/images/scene_1-bg.svg`,
        'images': [
            {
                id: 'scene_1-bg',
                src: `./assets/images/scene_1-bg.svg`,
            },
            {
                id: 'scene_1-lighthouse',
                src: `./assets/images/scene_1-lighthouse.svg`,
            },
            {
                id: 'scene_1-logo',
                src: `./assets/images/scene_1-logo.svg`,
            },
            {
                id: 'scene_1-building_light',
                src: `./assets/images/scene_1-building_light.svg`,
            },
            {
                id: 'scene_1-boot',
                src: `./assets/images/scene_1-boot.svg`,
            },
        ],
        dynamicHotspot: [
            {
                id: 'scene_1-plane',
                src: `./assets/images/scene_1-plane.svg`,
                imageStyle: {
                    'objectPosition': 'top'
                },
                hotspot: 23
            },
            {
                id: 'scene_1-container',
                src: `./assets/images/scene_1-container.svg`,
                hotspot: 2
            }
        ]
    },
    'scene-2': {
        'theme': 'light',
        'src': './assets/images/second.png',
        'bg': `./assets/images/scene_2-bg.svg`,
        hotspotList: [4, 5, 6, 7, 8, 10, 12, 14, 16, 25, 26, 27, 32],
        'images': [
            {
                id: 'scene_2-bg',
                src: `./assets/images/scene_2-bg.svg`,
            },
            {
                id: 'scene_2-wolken_1',
                src: `./assets/images/scene_2-wolken_1.svg`,
            },
            {
                id: 'scene_2-wolken_2',
                src: `./assets/images/scene_2-wolken_2.svg`,
            },
            {
                id: 'scene_2-haus',
                src: `./assets/images/scene_2-haus.svg`,
            },
            {
                id: 'scene_2-tv',
                src: `./assets/images/scene_2-tv.svg`,
            },
            {
                id: 'scene_2-soundwaves',
                src: `./assets/images/scene_2-soundwaves.svg`,
            },
            {
                id: 'scene_2-raum_dunkel',
                src: `./assets/images/scene_2-raum_dunkel.svg`,
            },
            {
                id: 'scene_2-garagentor',
                src: `./assets/images/scene_2-garagentor.svg`,
            },
            {
                id: 'scene_2-reflexe_glas',
                src: `./assets/images/scene_2-reflexe_glas.svg`,
            },
            {
                id: 'scene_2-auto',
                src: `./assets/images/scene_2-auto.svg`,
            },
            {
                id: 'scene_2-personen_vorne',
                src: `./assets/images/scene_2-personen_vorne.svg`,
            },
            {
                id: 'scene_2-ball',
                src: `./assets/images/scene_2-ball.svg`,
            },
        ],
        dynamicHotspot: [],
    },
    'scene-3': {
        'theme': 'light',
        'src': './assets/images/third.png',
        'bg': `./assets/images/scene_3-bg.svg`,
        hotspotList: [1, 3, 9, 13, 15, 28, 29, 30, 31, 33, 34],
        'images': [
            {
                id: 'scene_3-bg',
                src: `./assets/images/scene_3-bg.svg`,
            },
            {
                id: 'scene_3-layer_7_cloud_3',
                src: `./assets/images/scene_3-layer_7_cloud_3.svg`,
            },
            {
                id: 'scene_3-layer_7_cloud_2',
                src: `./assets/images/scene_3-layer_7_cloud_2.svg`,
            },
            {
                id: 'scene_3-layer_7_cloud_1',
                src: `./assets/images/scene_3-layer_7_cloud_1.svg`,
            },
            {
                id: 'scene_3-layer_6',
                src: `./assets/images/scene_3-layer_6.svg`,
            },
            {
                id: 'scene_3-layer_5_wheel',
                src: `./assets/images/scene_3-layer_5_wheel.svg`,
            },
            {
                id: 'scene_3-layer_4_antenna',
                src: `./assets/images/scene_3-layer_4_antenna.svg`,
            },
            {
                id: 'scene_3-layer_3',
                src: `./assets/images/scene_3-layer_3.svg`,
            },
            {
                id: 'scene_3-layer_2_drone',
                src: `./assets/images/scene_3-layer_2_drone.svg`,
            },
            {
                id: 'scene_3-layer_1',
                src: `./assets/images/scene_3-layer_1.svg`,
            },
        ],
        dynamicHotspot: [],
    }
}

class UtilsClass {
    useQuery = () => new URLSearchParams(document.location.search);

    getScene = (name: ISceneName): IScene => SCENE[name];
    getHotspotInfo = (id: number) => HotspotData[id];

    getScreenClass = (): string => {
        const {clientWidth, clientHeight} = document.documentElement;
        if (clientHeight > clientWidth) {
            return ' portrait';
        } else if (clientWidth / clientHeight > 16 / 9) {
            return ' fit-width';
        } else {
            return ''
        }
    }
}

const Utils = new UtilsClass()
export default Utils


