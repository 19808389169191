import {IHotspot} from "../interfaces";

const HotspotData: { [id in number]: IHotspot } = {
    1: {
        "id":1,
        "style": {
            "top": "46.2%",
            "left": "60%"
        },
        "side": "right",
        "name": "Architectural Coatings",
        "pigment": "KRONOS 2190 (TMP-free)",
        "scene": "scene-3",
        "integration": "Skysraper",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2190-type-3741/"
    },
    2: {
        "id":2,
        "style": {
            "right": "5.5%",
            "animation": "container 18s linear infinite"
        },
        "side": "left",
        "name": "Coil Coatings",
        "pigment": "KRONOS 2310",
        "scene": "scene-1",
        "integration": "Container",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2310-container/"
    },
    3: {
        "id":3,
        "style": {
            "top": "81%",
            "left": "59%"
        },
        "side": "right",
        "name": "Powder Coatings",
        "pigment": "KRONOS 2360",
        "scene": "scene-3",
        "integration": "Bicycle",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2360-bicycle/"
    },
    4: {
        "id":4,
        "style": {
            "top": "77%",
            "left": "32%"
        },
        "side": "right",
        "name": "Paper Laminates",
        "pigment": "KRONOS 2800",
        "scene": "scene-2",
        "integration": "Kitchen",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2800-kitchen/"
    },
    5: {
        "id":5,
        "style": {
            "top": "80%",
            "left": "7%"
        },
        "side": "left",
        "name": "Can Coatings",
        "pigment": "KRONOS 2300",
        "scene": "scene-2",
        "integration": "Can",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2300-can/"
    },
    6: {
        "id":6,
        "style": {
            "top": "88%",
            "left": "75%"
        },
        "side": "left",
        "name": "Automotive Coatings",
        "pigment": "KRONOS 2310",
        "scene": "scene-2",
        "integration": "Car",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2310-car/"
    },
    7: {
        "id":7,
        "style": {
            "top": "89%",
            "left": "56%"
        },
        "side": "left",
        "name": "Car Refinish",
        "pigment": "KRONOS 2160",
        "scene": "scene-2",
        "integration": "Car",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2160-car/"
    },
    8: {
        "id":8,
        "style": {
            "top": "43%",
            "left": "62%"
        },
        "side": "left",
        "name": "Interior Paints",
        "pigment": "KRONOS 2044",
        "scene": "scene-2",
        "integration": "House",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2044-interior-paints/"
    },
    9: {
        "id":9,
        "style": {
            "top": "43%",
            "left": "41%"
        },
        "side": "right",
        "name": "Facade Paints",
        "pigment": "KRONOS 4320",
        "scene": "scene-3",
        "integration": "House",
        "link": "https://www.kronosww.com/showroom-grade/kronos-4320-facade-paints/"
    },
    10: {
        "id": 10,
        "style": {
            "top": "48%",
            "left": "24%"
        },
        "side": "left",
        "name": "Wood Coatings",
        "pigment": "KRONOS 2064",
        "scene": "scene-2",
        "integration": "Wardrobe",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2064-wardrobe/"
    },
    11: {
        "id": 11,
        "style": {
            "top": "80%",
            "left": "10%"
        },
        "side": "right",
        "name": "Silicate Paints",
        "pigment": "KRONOS 2300",
        "scene": "scene-1",
        "integration": "Lighthouse",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2300-lighthouse/"
    },
    12: {
        "id": 12,
        "style": {
            "top": "82%",
            "left": "46%"
        },
        "side": "right",
        "name": "Mineral Plasters",
        "pigment": "KRONOS 2043",
        "scene": "scene-2",
        "integration": "House",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2043-plaster/"
    },
    13: {
        "id": 13,
        "style": {
            "top": "92%",
            "left": "4%"
        },
        "side": "right",
        "name": "Gravure Inks",
        "pigment": "KRONOS 2066",
        "scene": "scene-3",
        "integration": "Snack Bag",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2066-packaging/"
    },
    14: {
        "id": 14,
        "style": {
            "top": "43%",
            "left": "12%"
        },
        "side": "left",
        "name": "Digital Printing",
        "pigment": "KRONOS 9900",
        "scene": "scene-2",
        "integration": "T-Shirt",
        "link": "https://www.kronosww.com/showroom-grade/kronos-9900-tshirt/"
    },
    15: {
        "id": 15,
        "style": {
            "top": "25%",
            "left": "77%"
        },
        "side": "left",
        "name": "Cardboard Coatings",
        "pigment": "KRONOS 4045",
        "scene": "scene-3",
        "integration": "Cardboard/Drone",
        "link": "https://www.kronosww.com/showroom-grade/kronos-4045-label/"
    },
    16: {
        "id": 16,
        "style": {
            "top": "70%",
            "left": "68%"
        },
        "side": "right",
        "name": "Wood Coatings",
        "pigment": "KRONOS 2190",
        "scene": "scene-2",
        "integration": "Garage/Window",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2190-wooden-window/"
    },
    17: {
        "id": 17,
        "style": {
            "top": "55%",
            "left": "18%"
        },
        "side": "left",
        "name": "Protection Paints",
        "pigment": "KRONOS 4320",
        "scene": "scene-1",
        "integration": "Bridge",
        "link": "https://www.kronosww.com/showroom-grade/kronos-4320-bridge/"
    },
    18: {
        "id": 18,
        "style": {
            "top": "75%",
            "left": "33%"
        },
        "side": "left",
        "name": "Protection Paints",
        "pigment": "KRONOS 2160",
        "scene": "scene-1",
        "integration": "Bridge",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2160-bridge/"
    },
    19: {
        "id": 19,
        "style": {
            "top": "17%",
            "left": "34%"
        },
        "side": "left",
        "name": "Coil Coatings",
        "pigment": "KRONOS 2310",
        "scene": "scene-1",
        "integration": "Skyscraper",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2310-skyscraper/"
    },
    20: {
        "id": 20,
        "style": {
            "top": "83%",
            "left": "43%"
        },
        "side": "right",
        "name": "Gel Coats",
        "pigment": "KRONOS 2310",
        "scene": "scene-1",
        "integration": "Boat",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2310-gel-coats/"
    },
    21: {
        "id": 21,
        "style": {
            "top": "66%",
            "left": "60%"
        },
        "side": "left",
        "name": "LED Reflectors",
        "pigment": "KRONOS 2233",
        "scene": "scene-1",
        "integration": "Billboard",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2233-led-reflectors/"
    },
    22: {
        "id": 22,
        "style": {
            "top": "35%",
            "left": "72%"
        },
        "side": "left",
        "name": "Powder Coatings",
        "pigment": "KRONOS 2160",
        "scene": "scene-1",
        "integration": "Skyscraper",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2160-skyscraper/"
    },
    23: {
        "id": 23,
        "style": {
            "top": "6%",
            "animation": "plane-hs 25s linear infinite"
        },
        "side": "left",
        "name": "Polyurethane Coatings",
        "pigment": "KRONOS 2360",
        "scene": "scene-1",
        "integration": "Airplane",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2360-airplane/"
    },
    24: {
        "id": 24,
        "style": {
            "top": "62%",
            "left": "83%"
        },
        "side": "left",
        "name": "Air-drying Paints",
        "pigment": "KRONOS 2190",
        "scene": "scene-1",
        "integration": "Crane",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2190-crane/"
    },
    25: {
        "id": 25,
        "style": {
            "top": "28%",
            "left": "47%"
        },
        "side": "left",
        "name": "Polyolefine Masterbatch",
        "pigment": "KRONOS 2500",
        "scene": "scene-2",
        "integration": "Shampoo Bottle",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2450-shampoo/"
    },
    26: {
        "id": 26,
        "style": {
            "top": "39%",
            "left": "41%"
        },
        "side": "left",
        "name": "Cosmetics",
        "pigment": "KRONOS 1171",
        "scene": "scene-2",
        "integration": "Cosmetics",
        "link": "https://www.kronosww.com/showroom-grade/kronos-1171-cosmetics/"
    },
    27: {
        "id": 27,
        "style": {
            "top": "42%",
            "left": "80%"
        },
        "side": "right",
        "name": "Engineering Plastics",
        "pigment": "KRONOS 2230",
        "scene": "scene-2",
        "integration": "TV",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2230-tv/"
    },
    28: {
        "id": 28,
        "style": {
            "top": "35%",
            "left": "21%"
        },
        "side": "left",
        "name": "Fibers",
        "pigment": "KRONOS 1071",
        "scene": "scene-3",
        "integration": "Sunshade",
        "link": "https://www.kronosww.com/showroom-grade/kronos-1071-sunshade/"
    },
    29: {
        "id": 29,
        "style": {
            "top": "66%",
            "left": "23%"
        },
        "side": "right",
        "name": "Polycarbonates",
        "pigment": "KRONOS 2230",
        "scene": "scene-3",
        "integration": "Headphones",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2230-headphones/"
    },
    30: {
        "id": 30,
        "style": {
            "top": "75%",
            "left": "36%"
        },
        "side": "left",
        "name": "Powder Coatings",
        "pigment": "KRONOS 2310",
        "scene": "scene-3",
        "integration": "Bicycle Garage",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2310-bicycle-garage/"
    },
    31: {
        "id": 31,
        "style": {
            "top": "15%",
            "left": "31%"
        },
        "side": "right",
        "name": "Polycarbonates",
        "pigment": "KRONOS 2233",
        "scene": "scene-3",
        "integration": "5G Technology",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2233-5g-technology/"
    },
    32: {
        "id": 32,
        "style": {
            "top": "27%",
            "left": "85%"
        },
        "side": "right",
        "name": "Solar Reflective Roof",
        "pigment": "KRONOS 2360",
        "scene": "scene-2",
        "integration": "Roof",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2360-roof/"
    },
    33: {
        "id": 33,
        "style": {
            "top": "54%",
            "left": "68%"
        },
        "side": "right",
        "name": "Polycarbonates",
        "pigment": "KRONOS 2230",
        "scene": "scene-3",
        "integration": "Helmet",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2230-helmet/"
    },
    34: {
        "id": 34,
        "style": {
            "top": "52%",
            "left": "79%"
        },
        "side": "right",
        "name": "Super Durable Coatings",
        "pigment": "KRONOS 2360",
        "scene": "scene-3",
        "integration": "Wind Power",
        "link": "https://www.kronosww.com/showroom-grade/kronos-2360-wind-power/"
    },
}

export {HotspotData}
